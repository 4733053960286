import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "font-awesome/css/font-awesome.min.css";
import {BrowserRouter} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import GoogleFontLoader from 'react-google-font-loader';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <BrowserRouter>
      <GoogleFontLoader
        fonts={[
          {
            font: 'Fira Code',
            weights: [400],
          }
        ]}
      />
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </React.Fragment>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
