import React, {useEffect, useState} from "react";
import WithLoading from "../components/loadingWrapper";
import TransactionsTable from "../components/transactionsTable";
import BotService from "../services/bot.service";
import {formatDate, getPeriodDates, TIMEFRAME} from "../utils/dateFunctions";
import {Box, Grid, IconButton, Stack, Typography, Button} from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useOutletContext} from "react-router-dom";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

const BotTransactions = () => {
  const [isLoadingTable, setIsLoadingTable] = useState(true)
  const [tableData, setTableData] = useState([])
  const [date, setDate] = useState(() => {
      let today = new Date();
      return {
        month: today.getMonth(),
        year: today.getFullYear()
      }
    }
  )
  const [profit, setProfit] = useState('-0.00')
  const [profitTimeframe, setProfitTimeframe] = useState(TIMEFRAME.Max)
  const [isLoadingProfit, setIsLoadingProfit] = useState(true)
  const {isJwtTokenValid} = useOutletContext()

  useEffect(() => {
    if(!isJwtTokenValid()){
      return
    }

    setIsLoadingTable(true)
    const from = `${months[date.month]} 1, ${date.year}`
    const to = `${months[(date.month + 1) % 12]} 1, ${date.month !== 11 ? date.year : date.year + 1}`

    BotService.getTransactions(from, to).then(response => {
      setTableData(response.data.map((row) => ({
        date: formatDate(new Date(row.transactionDate)),
        action: !(row.orderSide ^ row.tradeSignal) ? "OPENED" : "CLOSED",
        side: row.orderSide ? "LONG" : "SHORT",
        amount: parseFloat(row.tradeSize).toFixed(3),
        price: row.price.toFixed(1),
        wallet: parseFloat(row.balance).toFixed(5),
        profit: parseFloat(row.profit).toFixed(5),
        profitPerc: ((row.balance / (row.balance - row.profit)) * 100 - 100).toFixed(2) + '%'
      })).sort((a, b) => (b.date > a.date) ? 1 : ((a.date > b.date) ? -1 : 0))
      )
      setIsLoadingTable(false)
    })
      .catch(err => {
        setIsLoadingTable(false)
      })
  }, [date, isJwtTokenValid]);

  useEffect(() => {
    const tf = getPeriodDates(profitTimeframe)
    BotService.getAllTimeProfit(tf.from, tf.to).then(response => {
      setProfit(parseFloat(response.data).toFixed(5))
      setIsLoadingProfit(false)
    })
      .catch(err => {})
  },[profitTimeframe])

  const handleDateButton = (increment) => {
    let month = (date.month + increment) % 12
    month = month < 0 ? 11 : month

    let year = date.year;
    if(month === 0 && increment > 0){
      year++
    }
    else if (month === 11 && increment < 0) {
      year--
    }
    setDate({month: month, year: year})
  }

  const handleSummaryProfit = (event) => {
    setIsLoadingProfit(true)
    setProfitTimeframe(event.target.value)
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "90vh",
          paddingTop: "1%"
        }}
      >
        <Typography variant={"h4"}>
          Transaction History
        </Typography>
        <WithLoading isLoading={isLoadingProfit} height={"9vh"} size={"2rem"}>
          <Stack direction={'row'} justifyItems={'center'} sx={{pb: 2, pt: 2, height: "9vh"}} spacing={2}>
            <Box sx={{pt: '12px', pb: '12px'}}>
              <Typography variant={"h5"}>Profit:</Typography>
            </Box>
            <Box sx={{pt: '12px', pb: '12px'}}>
              <Typography
                variant={"h5"}
                sx={ profit > 0 ? {color: "green"} : {color: "red"}}>
                {profit}
              </Typography>
            </Box>
          </Stack>
        </WithLoading>
        <Stack direction="row" spacing={2} sx={{mb: 3}}>
          <Button onClick={handleSummaryProfit} value={TIMEFRAME.Today} variant={"contained"} disabled={profitTimeframe === TIMEFRAME.Today}>1D</Button>
          <Button onClick={handleSummaryProfit} value={TIMEFRAME.ThisWeek} variant={"contained"} disabled={profitTimeframe === TIMEFRAME.ThisWeek}>1W</Button>
          <Button onClick={handleSummaryProfit} value={TIMEFRAME.ThisMonth} variant={"contained"} disabled={profitTimeframe === TIMEFRAME.ThisMonth}>1M</Button>
          <Button onClick={handleSummaryProfit} value={TIMEFRAME.Last3Months} variant={"contained"} disabled={profitTimeframe === TIMEFRAME.Last3Months}>3M</Button>
          <Button onClick={handleSummaryProfit} value={TIMEFRAME.Last6Months} variant={"contained"} disabled={profitTimeframe === TIMEFRAME.Last6Months}>6M</Button>
          <Button onClick={handleSummaryProfit} value={TIMEFRAME.ThisYear} variant={"contained"} disabled={profitTimeframe === TIMEFRAME.ThisYear}>1Y</Button>
          <Button onClick={handleSummaryProfit} value={TIMEFRAME.Max} variant={"contained"} disabled={profitTimeframe === TIMEFRAME.Max}>MAX</Button>
        </Stack>
        <Stack direction={'row'} justifyItems={'center'} sx={{mb: 2}}>
          <IconButton color={"primary"} size={"large"} onClick={() => {handleDateButton(-1)}}>
            <ArrowBackIosNewIcon size={"large"}/>
          </IconButton>
          <Box sx={{pt: '12px', pb: '12px'}}>
            <Typography variant={"h5"}>{months[date.month]}, {date.year}</Typography>
          </Box>
          <IconButton color={"primary"} size={"large"}
                      onClick={() => {handleDateButton(1)}}
                      disabled={new Date().getFullYear() + (new Date().getMonth() * 0.01) <= date.year + (date.month * 0.01)}
          >
            <ArrowForwardIosIcon size={"large"}/>
          </IconButton>
        </Stack>
        <WithLoading isLoading={isLoadingTable}>
          <Grid container justifyContent={"center"} spacing={2}>
            <Grid item lg={11} xs={12}>
              <TransactionsTable rows={tableData}/>
            </Grid>
          </Grid>
        </WithLoading>
      </div>
    </>
  );
};

export default BotTransactions;
