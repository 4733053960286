import React from 'react';
import Carousel from 'react-material-ui-carousel'
import {Paper} from '@mui/material'
import Typography from "@mui/material/Typography";

function InfoCarousel() {
  const items = [
    {
      url: "./tutorial/img_1.png",
      alt: "info1",
      description:
        "First, you have to pass yours Binance exchange api keys. They can be generated using your Binance account."
    },
    {
      url: "./tutorial/img_2.png",
      alt: "info2",
      description: "Make sure keys have proper permissions which are" +
      " 'Enable Reading', 'Enable Futures', 'Unrestricted (less secure)'."
    },
    {
      url: "./tutorial/img_3.png",
      alt: "info3",
      description: "After passing keys and selecting the strategy you prefer, click save button. The website will bind selected information to your account."
    },
    {
      url: "./tutorial/img_4.png",
      alt: "info4",
      description: "To lunch your bot press 'Enable bot' button."
    },
    {
      url: "./tutorial/img_5.png",
      alt: "info5",
      description: "You can disable bot or change bot's properties at any moment by clicking proper button."
    },
    {
      url: "./tutorial/img_6.png",
      alt: "info6",
      description: "There's a possibility that when disable bot or change bot's properties the popup window will appear. It means that bot has an open position on a exchange. " +
        "Clicking accept will disable bot and terminate the position which could result in money lost."
    }
  ]

  return (
    <Carousel
      autoPlay={false}
      navButtonsAlwaysVisible={true}
      sx={{minWidth: 1000}}
    >
      {
        items.map((item, i) => <Item key={i} item={item}/>)
      }
    </Carousel>
  )
}

function Item({item}) {
  return (
    <Paper
      elevation={0}
      sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}
    >
      <Typography sx={{fontSize: 18, mb: 2}}>
        {item.description}
      </Typography>
      <img
        src={item.url}
        height={400}
        width={"auto"}
        alt={item.alt}
      />
    </Paper>
  )
}

export default InfoCarousel