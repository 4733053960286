import React, {useEffect, useState} from "react";
import NavbarEx from "../components/Navbar";
import Footer from "../pages/footer";
import {ToastContainer} from "react-toastify";
import {Outlet} from "react-router-dom";
import TokenService from "../services/token.service";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

const MainLayout = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userRole, setUserRole] = useState(0);

  useEffect(() => {
    if (TokenService.isTokenPresent()) {
      if(TokenService.isTokenExpired())
      {
        setIsLogged(false);
        AuthService.logout();
        return
      }
      else {
        setIsLogged(true)
      }
    }

    if(isLogged) {
      UserService.getUserData().then((response) => {
        setUserName(response.data.name)
        setUserEmail(response.data.email)
        setUserRole(response.data.role)
      })
    }
  }, [isLogged]);

  return (
    <div className="container-fluid">
      <NavbarEx
        isLogged={isLogged}
        setIsLogged={setIsLogged}
        userName={userName}
        userEmail={userEmail}
        userRole={userRole}
      />
      <div style={{minHeight: "80vh", marginTop: 100, marginBottom: 100}}>
        <Outlet context={{isLogged, setIsLogged}}/>
      </div>
      <Footer/>
      <ToastContainer position="top-right" hideProgressBar/>
    </div>
  )
}

export default MainLayout;