import React, {useState} from "react";
import {
  Avatar,
  Box,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';

const AvatarMenu = ({userName, userEmail, setNavStyle}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setNavStyle(prevState => ({...prevState, paddingRight: 17}))
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setNavStyle(prevState => ({...prevState, paddingRight: 0}))
    setAnchorEl(null);
  };

  return (
    <div>
      <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ml: 2}}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{width: 32, height: 32}}>{userName.charAt(0)}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Popover
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.primary',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <Stack direction={"row"} alignItems="center" spacing={1} sx={{ p: 1 }}>
          <PersonIcon fontSize="small"/>
          <Typography >{userName}</Typography>
        </Stack>
        <Stack direction={"row"} alignItems="center" spacing={1} sx={{ p: 1 }}>
          <EmailIcon fontSize="small"/>
          <Typography>{userEmail}</Typography>
        </Stack>
      </Popover>
    </div>
  );
}

export default AvatarMenu;