import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types"
import {_, Grid} from 'gridjs-react';
import "gridjs/dist/theme/mermaid.css";
import {formatDate} from "../utils/dateFunctions"; // function for inserting react elements into table

const dateFormatter = (cell) => {
  return cell.replace(" ", ", ")
}

const currentPriceFormatter = (cell) => {
  return _(<b style={{color: "green"}}>{cell}</b>)
}

const cellFormatter = (cell) => cell

const columnLowerIndexFormatter = (name, index) => _(<div>{name} <sub style={{color: "#0d6efd"}}>[{index}]</sub></div>)

function createData({date, coin, entryPrice, stopLoss, takeProfit, currentPrice}) {
  return [date, coin, entryPrice, stopLoss, takeProfit, currentPrice];
}

function createColumn(id, name, width, formatter, sort) {
  return {id, name, width, formatter, sort}
}

const NUMBER_OF_COLUMNS = 7
const column_width = '' + 1.0 / NUMBER_OF_COLUMNS + '%'
const date_column_width = '' + 2.0 / NUMBER_OF_COLUMNS + '%'
const columns = [
  createColumn('entryDate', 'Entry Date', date_column_width, dateFormatter),
  createColumn('coin', 'Coin', column_width, cellFormatter, false),
  createColumn('entryPrice', columnLowerIndexFormatter('Entry price', 'USDT'), column_width, cellFormatter, false),
  createColumn('stopLoss', columnLowerIndexFormatter('Stop loss', 'USDT'), column_width, cellFormatter, false),
  createColumn('takeProfit', columnLowerIndexFormatter('Take profit', 'USDT'), column_width, cellFormatter, false),
  createColumn('currentPrice', columnLowerIndexFormatter('Current price', 'USDT'), column_width, currentPriceFormatter, false),
]

const OpenPositionsTable = ({rows}) => {
  const previousPrice = useRef(0.0)

  useEffect(() => {
    // no records do not open websocket
    if(!rows.length) {
      return
    }

    const ws = new WebSocket("wss://stream.binance.com:9443/stream");

    ws.onopen = () => {
      ws.send(JSON.stringify({
        method: 'SUBSCRIBE',
        params: ['btcusdt@kline_1m'],
        id: 1,
      }))
    }

    ws.onmessage = (event) => {
      const price = (JSON.parse(event?.data)?.data?.k?.c)
      if (price) {
        // using query selector prevent table reload and therefore flickering
        const bElement = document.querySelector("td[data-column-id=currentPrice] b")
        const currentPrice = parseFloat(price)

        bElement.style.color = currentPrice >= previousPrice.current ? "green" : "red"
        bElement.innerHTML = currentPrice.toFixed(2)
        previousPrice.current = currentPrice
      }
    };
    return () => {
      ws.close();
    };
  }, [previousPrice, rows.length])

  return (
    <Grid
      data={rows.map(row => createData({
        date: formatDate(new Date(row.transactionDate)),
        coin: "BTC",
        entryPrice: row.price.toFixed(2),
        stopLoss: parseFloat(row.stopLoss).toFixed(2),
        takeProfit: parseFloat(row.takeProfit).toFixed(2),
        currentPrice: 0.0
      }))}
      columns={columns.map(col => {
        if (['Entry Date', 'Coin'].includes(col.name)) {
          return {
            ...col,
            attributes: cell => {
              if (cell) {
                return {
                  'data-cell-content': cell,
                  'style': 'text-align: center',
                };
              }
            }
          }
        }
        return col
      })}
      sort={true}

      pagination={{
        enabled: false,
      }}

      className={{
        th: 'table-th',
        td: 'table-td',
        footer: 'table-footer'
      }}
    />
  )
}

OpenPositionsTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    transactionDate: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    stopLoss: PropTypes.string.isRequired,
    takeProfit: PropTypes.string.isRequired
  })).isRequired
}


export default OpenPositionsTable;
