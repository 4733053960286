import React from "react";
import {Badge, Box, Grid, Typography} from "@mui/material";
import PlanCard from "../components/planCard";
import styled from "@emotion/styled";

const cardsContent = [
  {
    title: "Basic",
    price: 15,
    description: "He is not afraid of risk, but I am also careful not to lose too much. This bot is a good average of risky and safe gaming.",
    body: [
      "Strategy: Rsi divergence",
      "Profits: ~ 0,8% per week",
      "Popularity: Popular",
      "Profits speed: Medium",
      "Safety: Medium"
    ],
  },
  {
    title: "Advanced",
    price: 25,
    description: "This bot is betting on risky but also very rewarding gaming. It can bring you great profit.",
    body: [
      "Strategy: Stochastic fast strategy",
      "Profits: ~ 1% per week",
      "Popularity: Not very popular",
      "Profits speed: Fast",
      "Safety: Low"
    ],
  }
]

const StyledBadge = styled(Badge)(({theme}) => ({
  '.MuiBadge-badge': {
    height: "auto",
    right: "15%",
    borderRadius: "100px"
  }
}))

const Products = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: "90vh"
      }}
    >
      <Box sx={{pt: 1}}>
        <Box sx={{textAlign: "center", mb: 3}}>
          <Typography variant={"h4"}>
            Pricing plans
          </Typography>
        </Box>
        <Grid container spacing={5}>
          {cardsContent.map((item, idx) =>
            <Grid item md={6} xs={12} key={idx}>
              {
                idx !== 1 ?
                  <PlanCard content={item}/>
                  :
                  <StyledBadge
                    badgeContent={
                      <Box sx={{padding: "4px 12px"}}>
                        <Typography sx={{fontSize: 20}}>
                          Best choice
                        </Typography>
                      </Box>
                    }
                    color={"warning"}>
                    <PlanCard content={item}/>
                  </StyledBadge>
              }
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default Products;
