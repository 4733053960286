import React, {useLayoutEffect, useRef} from "react";
import {NavLink} from "../components/Navbar/navbarElements";

const Footer = () => {
  const canvasEmailRef = useRef(null)

  useLayoutEffect(() => {
    const ctx = canvasEmailRef.current.getContext("2d");
    ctx.font = "20px Sora";
    ctx.fillStyle = "white";
    ctx.textAlign = "center";
    ctx.fillText("support@wisemex.com", canvasEmailRef.current.width/2, 24);
  },[])

  return (
    <div className="row bg-dark stick-bottom mt-5 pb-2 pt-4" style={{}} id="down">
      <div className="col-lg-4 col-md-12 col-sm-12 offset-lg-1 mb-2 mt-2">
        <div className="row text-white ">
          <h3 className="col-12 text-center">
            Products
          </h3>
        </div>
        <div className="row">
          {
            [
              {label: "Basic-plan", to: "/products"},
              {label: "Advanced-plan", to: "/products"},
            ].map((el, idx) =>
              <div className="col-md-6" key={idx}>
                <NavLink
                  to={el.to}
                  className="text-white d-flex justify-content-center align-items-center"
                >
                  {el.label}
                </NavLink>
              </div>
            )
          }
        </div>
      </div>
      <div className="col-lg-4 col-md-12 col-sm-12 offset-lg-2 text-center">
        <div className="row text-white">
          <h3 className="col-12 text-center">
            Contact email
          </h3>
        </div>
        <div className="col-md-12 text-white">
          <canvas ref={canvasEmailRef}>
            Your browser does not support the canvas element.
          </canvas>
        </div>
      </div>
      <div className="col-12 text-center mt-2">
        <div className="row text-white">
          <span className="col-12 text-center" style={{fontSize: 11}}>
            © Wisemex, All rights reserved.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
