import React, {useState} from "react";
import PropTypes from 'prop-types';
import {Collapse, IconButton, Paper, Stack, Typography} from "@mui/material";
import styled from "@emotion/styled";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
}));

const FaqCard = ({data}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Paper variant={"outlined"} sx={{p: 2, mt: 4}} onClick={handleExpandClick} style={{cursor: "pointer"}}>
      <Stack direction={"row"}>
        <Typography variant={"body1"}>
          {data.question}
        </Typography>
        <ExpandMore
          expand={expanded}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Stack>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Typography variant={"body2"} sx={{color: "text.secondary", mt: 2}}>
          {data.answer}
        </Typography>
      </Collapse>
    </Paper>
  )
}

FaqCard.propTypes = {
  data: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired
  }).isRequired
}

export default FaqCard