import React from 'react';
import {Navigate, Outlet, useNavigate, useOutletContext} from 'react-router-dom';
import TokenService from "../../services/token.service";
import AuthService from "../../services/auth.service";
import {toast} from "react-toastify";

const PrivateRoute = () => {
  let navigate = useNavigate()
  const {setIsLogged} = useOutletContext()

  const isJwtTokenValid = () => {
    if (!(TokenService.isTokenPresent() && !TokenService.isTokenExpired())) {
      AuthService.logout();
      setIsLogged(false)
      navigate("/sign-in")
      toast.warn("You has been logged out!");
      return false;
    }
    return true
  }

  const accessPrivateRoute = () => {
    if(TokenService.isTokenPresent() && !TokenService.isTokenExpired()){
      return true
    }
    setIsLogged(false)
    return false
  }

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return accessPrivateRoute() ? <Outlet context={{isJwtTokenValid}}/> : <Navigate to="/sign-in" />;
}

export default PrivateRoute