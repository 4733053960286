import api from "./api";

const getUserData = () => {
  return api.get("/accounts/userData")
}

const UserService = {
  getUserData
};

export default UserService;