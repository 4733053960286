import React, {useState, useRef} from "react";
import "../App.css";
import {Formik} from "formik";
import * as Yup from "yup";
import {Link, NavLink} from "react-router-dom";
import {toast} from "react-toastify";
import AuthService from "../services/auth.service";
import LoadingButton from "@mui/lab/LoadingButton";
import GoogleCaptcha from "../components/googleCaptcha";

// Creating schema
const schema = Yup.object().shape({
  name: Yup.string().required("Username is  required field"),
  email: Yup.string()
    .required("Email is required field")
    .email("Invalid email format"),
  password: Yup.string()
    .required("Password is required field")
    .min(8, "Password must be at least 8 characters"),
  term: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
});

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const captchaRef = useRef()
  // const [isCaptchaOpen, setIsCaptchaOpen] = useState(false)

  const handleRegister = async (values, {resetForm}) => {
    setLoading(true)
    captchaRef.current.reset()
    const token = await captchaRef.current.executeAsync()
    AuthService.verifyCaptcha(token).then(() => {

      AuthService.register(
        values.name,
        values.email,
        values.password
      ).then(
        (response) => {
          if (response?.status === "ok") {
            setMessage(response.message);
            toast.success(response.message);
            resetForm();
          } else if (response?.status === "exist") {
            setMessage(response?.message);
            toast.error(response.message);
          }
          setLoading(false)
        },
        (error) => {
        }
      );

    }).catch(() => {
      setLoading(false)
    })


  }

  return (
    <>
      <div className="row d-flex align-content-center" style={{minHeight: "80vh"}}>
        <div
          className="col-lg-3 col-md-4 col-sm-5 col-xs-8 offset-md-2 d-none d-sm-flex justify-content-center align-items-center mb-4 ">
          <img
            src={require("../images/trading.png")}
            height={"auto"}
            width={"100%"}
            alt="logo"
          />
        </div>
        <div className="col-lg-4 col-md-6 col-sm-8 col-xs-12">
          <Formik
            validationSchema={schema}
            initialValues={{name: "", email: "", password: "", term: false}}
            // onSubmit={() => setIsCaptchaOpen(true)}
            onSubmit={handleRegister}
          >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
              <>
                <div className="form">
                  <form noValidate onSubmit={handleSubmit}>

                    {/*<Dialog*/}
                    {/*  open={isCaptchaOpen}*/}
                    {/*>*/}
                    {/*  <DialogTitle>*/}
                    {/*    Enter Captcha*/}
                    {/*  </DialogTitle>*/}
                    {/*  <DialogContent>*/}
                    {/*    <Captcha setClose={setIsCaptchaOpen} handleLogin={handleRegister}/>*/}
                    {/*  </DialogContent>*/}
                    {/*</Dialog>*/}

                    <div className="row ">
                      <h3 className="d-flex justify-content-center">
                        Create your account
                      </h3>
                    </div>
                    {
                      [
                        {
                          name: "name",
                          type: "text",
                          value: values.name,
                          placeholder: "Username",
                          id: "name",
                          error: errors.name && touched.name && errors.name,
                          icon: "fa-user"
                        },
                        {
                          name: "email",
                          type: "email",
                          value: values.email,
                          placeholder: "Email",
                          id: "email",
                          error: errors.email && touched.email && errors.email,
                          icon: "fa-envelope"
                        },
                        {
                          name: "password",
                          type: "password",
                          value: values.password,
                          placeholder: "Password",
                          id: "password",
                          error: errors.password && touched.password && errors.password,
                          icon: "fa-lock"
                        }
                      ].map((el, idx) =>
                        <div key={idx}>
                          <div className="input-group mt-4">
                            <span
                              className={`input-group-text icon-style fa ${el.icon}`}
                              id="basic-addon1"
                            />
                            <input
                              type={el.type}
                              name={el.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={el.value}
                              placeholder={el.placeholder}
                              className="form-control"
                              id={el.id}
                            />
                          </div>
                          <div className="error">
                            {el.error}
                          </div>
                        </div>
                      )
                    }
                    <div className="form-check mt-3 ">
                      <input
                        name="term"
                        type="checkbox"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.term}
                        className="form-check-input ml-4"
                        id="term"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="term"
                        style={{cursor: "pointer"}}
                      >
                        I agree to <Link to="#">Terms & and conditions</Link>
                        <span className="error">
                          {errors.term && touched.term && errors.term}
                        </span>
                      </label>
                    </div>
                    <GoogleCaptcha
                      recaptchaRef={captchaRef}/>
                    <LoadingButton
                      type="submit"
                      className="mt-2"
                      style={{width: "100%"}}
                      loading={loading}
                      loadingPosition="center"
                      variant="contained"
                    >
                      Create account
                    </LoadingButton>
                    {message.length > 0 ? (
                      <div
                        className={`alert ${
                          message === "Successfully Registered"
                            ? "alert-success"
                            : "alert-danger"
                        } mt-2 d-flex justify-content-center`}
                        role="alert"
                        style={{fontWeight: "bold"}}
                      >
                        {message}
                      </div>
                    ) : (
                      <></>
                    )}
                  </form>
                </div>
                <div className="row mt-2">
                  <div className="col d-flex justify-content-center">
                    Already have an account?&nbsp;&nbsp;
                    <NavLink to="/sign-in">Sign in</NavLink>
                  </div>
                </div>
              </>
            )}
          </Formik>
        </div>

        {/* Wrapping form inside formik tag and passing our schema to validationSchema prop */}
      </div>
    </>
  );
};

export default SignUp;
