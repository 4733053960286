import React, {useEffect, useRef, useState} from "react";
import "../App.css";
import {Formik} from "formik";
import * as Yup from "yup";
import {NavLink, useNavigate, useOutletContext} from "react-router-dom";
import AuthService from "../services/auth.service";
import {toast} from "react-toastify";
import LoadingButton from '@mui/lab/LoadingButton';
import GoogleCaptcha from "../components/googleCaptcha";

// Creating schema
const schema = Yup.object().shape({
  email: Yup.string()
    .required("Email is a required field")
    .email("Invalid email format"),
  password: Yup.string()
    .required("Password is a required field")
    .min(5, "Password must be at least 8 characters"),
});

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  // const [isCaptchaOpen, setIsCaptchaOpen] = useState(false)
  const captchaRef = useRef()
  const {setIsLogged} = useOutletContext()
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
    remember: false,
  });
  let navigate = useNavigate();

  useEffect(() => {
    let values = JSON.parse(localStorage.getItem("remember"));
    if (values?.remember) {
      setInitialValues(values);
    }
  }, []);

  const handleLogin = async (values) => {
    setMessage("")
    setLoading(true)

    // executeAsync() method is only intended to be called once per captcha instance.
    // If you want to get a new token, you need to reset the captcha and call the executeAsync() method again.
    captchaRef.current.reset()
    const token = await captchaRef.current.executeAsync()
    AuthService.verifyCaptcha(token).then(() => {

      // Alert the input values of the form that we filled
      if (values.remember) {
        localStorage.setItem("remember", JSON.stringify(values));
      }

      AuthService.login(values.email, values.password).then(
        (response) => {
          if (response?.status === "ok") {
            setMessage(response.message);
            toast.success(response.message);
            setIsLogged(true);
            navigate("/crypto-bots");
          } else if (response?.status === "not authenticated") {
            navigate("/authentication");
          } else {
            setMessage(response ? response.message : "Internal server error");
            toast.error(response ? response.message : "Internal server error");
          }
          setLoading(false)
        },
        (error) => {
        }
      );

    }).catch(() => {
      setLoading(false)
    })

  }

  return (
    <div className="row d-flex align-content-center" style={{minHeight: "80vh"}}>
      <div className="col-md-4 col-sm-8 col-xs-12 offset-md-4">
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleLogin}
        >
          {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
            <>
              <div className="form">
                <form noValidate onSubmit={handleSubmit}>

                  {/*<Dialog*/}
                  {/*  open={isCaptchaOpen}*/}
                  {/*>*/}
                  {/*  <DialogTitle>*/}
                  {/*    Enter Captcha*/}
                  {/*  </DialogTitle>*/}
                  {/*  <DialogContent>*/}
                  {/*    <Captcha setClose={setIsCaptchaOpen} handleLogin={handleLogin}/>*/}
                  {/*  </DialogContent>*/}
                  {/*</Dialog>*/}

                  <div className="row ">
                    <h3 className="d-flex justify-content-center">LogIn</h3>
                  </div>

                  <div className="input-group mt-4">
                    <span
                      className="input-group-text icon-style fa fa-envelope"
                      id="basic-addon1"
                    />
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder="Enter email"
                      className="form-control"
                      id="email"
                    />
                  </div>
                  <div className="error">
                    {errors.email && touched.email && errors.email}
                  </div>
                  <div
                    className="input-group mt-4 border"
                    style={{
                      borderStyle: "solid ",
                      borderColor: "red",
                      borderWidth: "3px",
                    }}
                  >
                    <span
                      className="input-group-text icon-style fa fa-lock"
                      id="basic-addon1"
                    />
                    <input
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      placeholder="Enter password"
                      className="form-control"
                    />
                  </div>
                  <div className="error">
                    {errors.password && touched.password && errors.password}
                  </div>
                  <div className="form-check mt-3  ">
                    <input
                      type="checkbox"
                      name="remember"
                      id="remember"
                      className="form-check-input ml-4"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.remember}
                    />
                    <label
                      htmlFor="remember"
                      className=""
                      style={{cursor: "pointer"}}
                    >
                      Remember me
                    </label>
                  </div>
                  <GoogleCaptcha
                    recaptchaRef={captchaRef}/>
                  <LoadingButton
                    type="submit"
                    className="mt-2"
                    style={{width: "100%"}}
                    loading={loading}
                    loadingPosition="center"
                    variant="contained"
                  >
                    Log into account
                  </LoadingButton>
                  {message.length > 0 ? (
                    <div
                      className="alert alert-danger mt-2 d-flex justify-content-center"
                      role="alert"
                      style={{fontWeight: "bold"}}
                    >
                      {message}
                    </div>
                  ) : (
                    <></>
                  )}
                </form>
              </div>
              <div className="row mt-2">
                <div className="col d-flex justify-content-center">
                  Don't have an account?&nbsp;&nbsp;
                  <NavLink to="/sign-up">SignUp</NavLink>
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>

      {/* Wrapping form inside formik tag and passing our schema to validationSchema prop */}
    </div>
  );
};

export default SignIn;
