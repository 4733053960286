import React, {useState} from "react";
import {Avatar, Box, Button, Divider, Grid, Paper, Stack, TextField, Typography} from "@mui/material";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import {green} from "@mui/material/colors";
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css'
import {CardCVCInput, CardExpiryInput, CardNameInput, CardNumberInput} from '../components/customInputs'
import SelectedPlanCard from "../components/selectedPlanCard";
import {useLocation, useNavigate} from "react-router-dom";
import {styled} from '@mui/material/styles';

const StyledBox = styled(Box)(({theme}) => ({
  [theme.breakpoints.down('md')]: {
    width: "100%",
  },
  [theme.breakpoints.up('md')]: {
    width: "100%",
  },
  [theme.breakpoints.up('lg')]: {
    width: "85%",
  },
  [theme.breakpoints.up('xl')]: {
    width: "70%",
  },
}))

const Payment = () => {
  const {state} = useLocation();
  const {title, body, price} = state;
  let navigate = useNavigate()
  const [creditCard, setCreditCard] = useState({
    number: "",
    name: "",
    expiry: "",
    cvc: "",
    focused: ""
  })

  const handleChange = (event) => {
    setCreditCard(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value
    }))
  }

  const handleFocus = (event) => {
    setCreditCard(prevState => ({
      ...prevState,
      focused: event.target.name
    }))
  }

  const handleBlur = () => {
    setCreditCard(prevState => ({
      ...prevState,
      focused: ""
    }))
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: "90vh",
        width: "100%"
      }}
    >
      <StyledBox sx={{pt: 1}}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Paper variant="outlined" sx={{p: 2}}>
              <Stack direction={"row"} alignItems="center" spacing={2}>
                <Avatar sx={{backgroundColor: green[500], width: 50, height: 50}}>
                  <LocalActivityIcon fontSize={"large"}/>
                </Avatar>
                <Box>
                  <Typography gutterBottom variant={"h5"}>
                    Subscription
                  </Typography>
                  <Typography variant={"body1"}>
                    In order to use bot set up a subscription
                  </Typography>
                </Box>
              </Stack>
              <Divider sx={{mb: 2, mt: 2}}/>
              <Grid container spacing={5}>
                <Grid item md={6} xs={12}>
                  <Paper variant={"outlined"} sx={{p: 2}}>
                    <Typography variant={"h6"}>
                      Selected Plan
                    </Typography>
                    <Divider sx={{mb: 2, mt: 2}}/>
                    <Grid container justifyContent="center" spacing={2}>
                      <Grid item lg={8} xs={12}>
                        <SelectedPlanCard title={title} body={body}/>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container justifyContent={"space-between"} spacing={2}>
                          <Grid item md={4} xs={12}>
                            <Button fullWidth variant={"contained"} onClick={() => navigate("/products")}>
                              Change Plan
                            </Button>
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <Button fullWidth variant={"contained"} color={"error"} onClick={() => navigate("/products")}>
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Paper>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Paper variant={"outlined"} sx={{p: 2}}>
                    <Typography variant={"h6"}>
                      Payment
                    </Typography>
                    <Divider sx={{mb: 2, mt: 2}}/>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Cards
                          cvc={creditCard.cvc}
                          expiry={creditCard.expiry}
                          focused={creditCard.focused}
                          name={creditCard.name}
                          number={creditCard.number}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputProps={{
                            inputComponent: CardNumberInput,
                          }}
                          fullWidth
                          size={"small"}
                          label="Number"
                          name="number"
                          value={creditCard.number}
                          onChange={handleChange}
                          onFocus={handleFocus}
                          onBlur={handleBlur}/>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputProps={{
                            inputComponent: CardNameInput,
                          }}
                          fullWidth
                          size={"small"}
                          label="Name"
                          name="name"
                          value={creditCard.name}
                          onChange={handleChange}
                          onFocus={handleFocus}
                          onBlur={handleBlur}/>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputProps={{
                            inputComponent: CardExpiryInput,
                          }}
                          fullWidth
                          size={"small"}
                          label="Expiration date"
                          placeholder={"mm / YY"}
                          name="expiry"
                          value={creditCard.expiry}
                          onChange={handleChange}
                          onFocus={handleFocus}
                          onBlur={handleBlur}/>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          InputProps={{
                            inputComponent: CardCVCInput,
                          }}
                          fullWidth
                          size={"small"}
                          label="CVC"
                          name="cvc"
                          value={creditCard.cvc}
                          onChange={handleChange}
                          onFocus={handleFocus}
                          onBlur={handleBlur}/>
                      </Grid>
                      <Grid item xs={12} sx={{mt: 2}}>
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <Typography variant={"h5"}>
                              Total
                            </Typography>
                            <Typography variant={"body2"}>
                              Due on today, then every month
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant={"h4"} sx={{textAlign: "end"}}>
                              $ {price}.00
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Button fullWidth variant={"contained"} onClick={() => alert("Paid")}>
                          Proceed Payment
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </StyledBox>
    </div>
  )
}

export default Payment;