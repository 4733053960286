import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck as fasFaCircle, faCircleXmark as fasFaCross} from "@fortawesome/free-solid-svg-icons";
import BotService from "../services/bot.service";
import {useOutletContext} from "react-router-dom";
import WithLoading from "../components/loadingWrapper";
import Button from 'react-bootstrap/Button';
import {toast} from "react-toastify";
import axios from "axios";
import {Form, InputGroup} from "react-bootstrap";
import InfoDialog from "../components/infoDialog";
import IconButton from "@mui/material/IconButton";
import InfoIcon from '@mui/icons-material/Info';
import AlertDialog from "../components/alertDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import {Button as FormButton, Grid, Typography} from "@mui/material";
import OpenPositionsTable from "../components/openPositionsTable";

const CryptoBots = () => {
  const [isBotActive, setIsBotActive] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [botData, setBotData] = useState({
    id: 0,
    publicKey: "",
    secretKey: "",
    market: 0,
    exchange: "Binance",
    strategy: 1,
    openPosition: []
  });
  const [tempBotData, setTempBotData] = useState()
  const [areKeysVisible, setAreKeysVisible] = useState({
    publicKey: false,
    secretKey: false,
  })
  const [strategies, setStrategies] = useState([])
  const [publicError, setPublicError] = useState("");
  const [secretError, setSecretError] = useState("");
  const [openInfoDialog, setOpenInfoDialog] = useState(false)
  const [alertDialog, setAlertDialog] = useState({
    open: false,
    callback: () => {}
  })
  const [isLoading, setIsLoading] = useState(true)
  const [isAwaitingRequestRespond, setIsAwaitingRequestRespond] = useState(false)
  const {isJwtTokenValid} = useOutletContext()


  const handleChange = (event) => {
    event.persist();
    setPublicError("");
    setSecretError("");
    setBotData((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleKeysVisibility = (event) => {
    setAreKeysVisible((values) => ({
      ...values,
      [event.target.name]: !values[event.target.name],
    }));
  }

  useEffect(() => {
    BotService.getTemplates()
      .then(response => {
        setStrategies(response.data.map(e => ({
          id: e.oid,
          name: `${e.strategy}_${e.interval}_${e.cryptoPair}`
        })))
      })

    const apiKeysRequest = BotService.getApiKeys(0)
    const botRequest = BotService.getBots()

    axios.all([apiKeysRequest, botRequest]).then(axios.spread((...responses) => {
      const apiKeysResponse = responses[0]
      const botResponse = responses[1]

      setBotData(prevState => ({
        ...prevState,
        id: botResponse.data.oid,
        publicKey: apiKeysResponse.data.publicKey,
        secretKey: apiKeysResponse.data.secretKey,
        strategy: botResponse.data.botSchema.oid,
        openPosition: botResponse.data.botTransactions
      }));

      setIsFormDisabled(
        apiKeysResponse.data.publicKey.length > 0 &&
        apiKeysResponse.data.secretKey.length > 0
      );

      setIsBotActive(botResponse.data.isActive)
      setIsLoading(false)
    })).catch(error => {
      if (error.response.status === 404) {
        setIsLoading(false)
      } else {
        toast.error("Error when fetching data");
      }
    })
  }, []);

  const checkForPosition = (callback) => {
    if(!isJwtTokenValid()){
      return
    }

    setIsAwaitingRequestRespond(true)
    if(botData.id === 0){
      callback()
      return
    }

    const updateInfoRequest = BotService.getUpdateInfo(botData.id)
    const botRequest = BotService.getBots()

    axios.all([updateInfoRequest, botRequest]).then(axios.spread((...responses) => {
      const updateInfoResponse = responses[0]
      const botResponse = responses[1]

      if(updateInfoResponse.data.forUpdate) {
        callback()
        return
      }

      if(botResponse.data.hasOpenPosition && isBotActive) {
        setAlertDialog({open: true, callback})
        return
      }
      callback()
    }))
  }

  const submitBotValues = () => {
    if (botData.publicKey.length === 0) {
      setPublicError("Required field");
      setIsAwaitingRequestRespond(false)
      return false;
    } else if (botData.secretKey.length === 0) {
      setIsAwaitingRequestRespond(false)
      setSecretError("Required field");
      return false;
    }

    BotService.addApiKeys(
      botData?.publicKey,
      botData?.secretKey,
      botData?.market,
      botData?.exchange
    ).then(response => {
      if (response.status === 409) {
        setPublicError("Invalid Api keys");
        setSecretError("Invalid Api keys");
        toast.error("Api keys are invalid");
        setIsAwaitingRequestRespond(false)
        return
      }
      if (!response.bots) {
        BotService.addBot(botData.strategy, response.oid)
          .then(resp => {
            setBotData(prevState => ({
              ...prevState,
              id: resp.data.oid,
            }))
          })
          .catch(err => {
            toast.error("Unknown error has occurred");
            setBotData(prevState => ({
              ...prevState,
              publicKey: "",
              secretKey: ""
            }))
          })
      } else {
        BotService.updateBot(botData.strategy, response.oid)
          .then(resp => {
          })
          .catch(err => {
            console.log(err)
            toast.error("Unknown error has occurred");
          })
      }
      toast.success("Changes Saved")
      setIsFormDisabled((prevState => !prevState));
      setPublicError("");
      setSecretError("");
      setIsAwaitingRequestRespond(false)
    })
  };

  const handleToggleBot = () => {
    BotService.toggleBot(botData.id)
      .then(resp => {
        setIsBotActive(prevState => !prevState)
        setIsAwaitingRequestRespond(false)
      })
      .catch(err => {
      })
  }

  const toggleFormDisabled = (isCancel) => {
    if (isCancel) {
      setBotData({...tempBotData})
      setAreKeysVisible({secretKey: false, publicKey: false})
      setPublicError("");
      setSecretError("");
    } else {
      setTempBotData({...botData})
    }
    setIsFormDisabled(prevState => !prevState)
  }

  return (
    <div className="row" style={{marginTop: "7%"}}>
      <WithLoading isLoading={isLoading}>
        <InfoDialog open={openInfoDialog} setOpen={setOpenInfoDialog}/>
        <AlertDialog
          handleDenied={() => {
            setAlertDialog({open: false, callback: () => {}})
            setIsAwaitingRequestRespond(false)
          }}
          handleApprove={() => {
            alertDialog.callback()
            setAlertDialog({open: false, callback: () => {}})
          }}
          open={alertDialog.open}/>
        <div className="col-md-2 offset-md-2">
          <img
            src={require(`../images/bot-trade.png`)}
            width={"100%"}
            height={"250px"}
            alt={"Crypto bot"}
          />
        </div>
        <div className="col-md-6">
          <div className="row mt-sm-1 mt-5">
            <div className="col-md-3 col-6 d-flex">
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  icon={!isBotActive ? fasFaCross : fasFaCircle}
                  style={{
                    fontSize: "50px",
                    color: `${!isBotActive ? "#c71a1a" : "#26821f"}`,
                    paddingRight: 10,
                  }}
                />
                <span className="pr-3">{isBotActive ? "Active" : "Inactive"}</span>
              </div>
            </div>
            {/*<div className="col-md-3 offset-md-5 col-3 offset-0">*/}
            {/*  <div className="d-flex align-items-center" style={{height: '100%'}}>*/}
            {/*    <span>*/}
            {/*      {botData.isPositionOpen ? 'Opened position' : 'No position opened'}*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="col-md-1 offset-md-8 col-3 offset-3">
              <IconButton aria-label="info" onClick={() => {
                setOpenInfoDialog(true)
              }}>
                <InfoIcon color={"primary"} style={{fontSize: 50}}/>
              </IconButton>
            </div>
          </div>
          <div className="row mt-4">
            {
              [
                {
                  name: "publicKey",
                  label: "Public key",
                  value: botData.publicKey,
                  visibility: areKeysVisible.publicKey,
                  error: publicError
                },
                {
                  name: "secretKey",
                  label: "Secret Key",
                  value: botData.secretKey,
                  visibility: areKeysVisible.secretKey,
                  error: secretError
                }
              ].map((el, idx) =>
                <div className="col-md-6 pb-3" key={idx}>
                  <div
                    className="p-1 font-weight-bold"
                    style={{fontWeight: "bold"}}
                  >
                    {el.label}
                  </div>
                  <InputGroup>
                    <Form.Control
                      type={el.visibility ? "text" : "password"}
                      name={el.name}
                      onChange={handleChange}
                      value={el.value || ""}
                      className="form-control"
                      autoComplete="off"
                      disabled={isFormDisabled}
                    />
                    <Button
                      variant={"outline-secondary"}
                      disabled={isFormDisabled}
                      onClick={handleKeysVisibility}
                      name={el.name}
                      className={el.visibility ? "icon-style fa fa-eye-slash" : "icon-style fa fa-eye"}
                    />
                  </InputGroup>
                  <div className="text-danger">{el.error}</div>
                </div>
              )
            }
            <div className="col-md-6 pb-3">
              <div
                className="p-1 font-weight-bold"
                style={{fontWeight: "bold"}}
              >
                Strategy
              </div>
              <Form.Select aria-label="Strategy"
                           name="strategy"
                           value={botData.strategy}
                           onChange={handleChange}
                           disabled={isFormDisabled}>
                {
                  strategies.map((e, idx) =>
                    <option key={idx} value={e.id}>{e.name}</option>
                  )
                }
              </Form.Select>
            </div>
            <div className="col-md-12 d-flex justify-content-center align-items-center mt-3">
              {
                isFormDisabled ?
                  <>
                    <FormButton
                      variant="contained"
                      size={"large"}
                      onClick={() => toggleFormDisabled(false)}>
                      Change Properties
                    </FormButton>
                    {
                      botData.publicKey && botData.secretKey ?
                        isBotActive ?
                          <LoadingButton
                            variant="contained"
                            loading={isAwaitingRequestRespond}
                            size={"large"}
                            className={"ms-5"}
                            onClick={() => checkForPosition(handleToggleBot)}>
                            Disable bot
                          </LoadingButton>
                          :
                          <LoadingButton
                            variant="contained"
                            loading={isAwaitingRequestRespond}
                            size={"large"}
                            className={"ms-5"}
                            onClick={() => checkForPosition(handleToggleBot)}>
                            Enable bot
                          </LoadingButton>
                        :
                        <></>
                    }
                  </>
                  :
                  <>
                    <LoadingButton
                      size={"large"}
                      loading={isAwaitingRequestRespond}
                      loadingPosition="center"
                      variant="contained"
                      onClick={() => checkForPosition(submitBotValues)}
                    >
                      Save
                    </LoadingButton>
                    <FormButton
                      variant="contained"
                      size={"large"}
                      className={"ms-5"}
                      onClick={() => toggleFormDisabled(true)}>
                      Cancel
                    </FormButton>
                  </>
              }
            </div>
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}} className={"mt-5"}>
          <Typography variant={"h5"} gutterBottom>
            Open Positions
          </Typography>
        </div>
        <Grid container justifyContent={"center"} spacing={2}>
          <Grid item lg={11} xs={12}>
            <OpenPositionsTable rows={
              botData.openPosition
            }/>
          </Grid>
        </Grid>
      </WithLoading>
    </div>
  );
};

export default CryptoBots;
