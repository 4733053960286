import React, {useEffect, useState} from 'react';
import simulation_api from "../services/api_simulation_chart"
import {Form} from "react-bootstrap";
import WithLoading from "../components/loadingWrapper";
import SimulationChart from "../components/simulationChart";
import {toast} from "react-toastify";
import TokenService from "../services/token.service";
import AuthService from "../services/auth.service";
import {useNavigate, useOutletContext} from "react-router-dom";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import {Button, Grid, Typography} from "@mui/material";

const Simulation = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [chartData, setChartData] = useState(null)
  const [inputsData, setInputsData] = useState({
    startCash: 1000,
    endCash: "",
    strategy: "EMA_STOCH",
    cryptoPair: "BTCUSDT",
    interval: "15m",
    month: 7
  })
  const [inputsError, setInputsError] = useState(false)
  const {isLogged, setIsLogged} = useOutletContext()
  let navigate = useNavigate()


  const ValidToken = () => {
    if (TokenService.isTokenPresent()) {
      if (TokenService.isTokenExpired()) {
        AuthService.logout();
        return false;
      }
      return true
    }
    return false
  };

  useEffect(() => {
    if (TokenService.getUser() == null || !ValidToken()) {
      setIsLogged(false);
    }
  }, [setIsLogged])

  const handleChange = (evt) => {
    const value = evt.target.value;
    setInputsData(prevState => ({
      ...prevState,
      [evt.target.name]: value
    }));
  }

  const fetchTestData = () => {
    setIsLoading(true)
    setInputsError(false)
    let startMonth = parseInt(inputsData.month)
    let endMonth = (parseInt(inputsData.month) + 1) <= 12 ? (parseInt(inputsData.month) + 1) : 1
    let endYear = (parseInt(inputsData.month) + 1) <= 12 ? 2021 : 2022

    simulation_api.get('/test', {
      params:
        {
          cash: inputsData.startCash,
          strategy: inputsData.strategy,
          crypto_pair: inputsData.cryptoPair,
          interval: inputsData.interval,
          from: `2021-${('0' + startMonth).slice(-2)}-01 00:00:00`,
          to: `${endYear}-${('0' + endMonth).slice(-2)}-01 00:00:00`,
          is_futures: true,
        }
    }).then(response => {
      setChartData(response.data)
      setIsLoading(false)
    }).catch(error => {
      toast.error("Error when fetching data");
      setInputsData(prevState => ({...prevState, endCash: ""}))
      setChartData(null)
      setIsLoading(false)
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        minHeight: '90vh',
        flexDirection: 'column',
        paddingTop: '1%'
      }}
    >
      {
        !isLogged ?
          <>
            <SentimentVeryDissatisfiedIcon style={{fontSize: 200}}/>
            <p style={{fontSize: 24, textAlign: "center"}}>Simulation is only for sign up users</p>
            <Button variant={"contained"} onClick={() => navigate("/sign-in")}>Login</Button>
          </>
          :
          <WithLoading isLoading={isLoading}>
            <Typography variant={"h4"}>
              Simulation
            </Typography>
            <Grid container justifyContent={"center"}>
              <Grid item lg={6} sm={8} xs={12}>
                <div className="row gy-3">
                  {
                    [
                      {label: "Start cash", name: "startCash", value: inputsData.startCash, disabled: false},
                      {label: "End cash", name: "endCash", value: inputsData.endCash, disabled: true}
                    ].map((el, idx) =>
                      <div className="col-md-6" key={idx}>
                        <div
                          className="p-1 font-weight-bold"
                          style={{fontWeight: "bold"}}>
                          {el.label}
                        </div>
                        <input
                          type="number"
                          name={el.name}
                          onChange={handleChange}
                          value={el.value}
                          className="form-control"
                          autoComplete="off"
                          disabled={el.disabled}
                        />
                      </div>
                    )
                  }
                  <div className="col-md-4">
                    <div
                      className="p-1 font-weight-bold"
                      style={{fontWeight: "bold"}}>
                      Strategy
                    </div>
                    <Form.Select aria-label="Strategy"
                                 name="strategy"
                                 value={inputsData.strategy}
                                 onChange={handleChange}>
                      <option value="EMA_STOCH">EMA_STOCH</option>
                      <option value="EMA_MACD">EMA_MACD</option>
                      <option value="MACD_RSI">MACD_RSI</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="p-1 font-weight-bold"
                      style={{fontWeight: "bold"}}>
                      Crypto pair
                    </div>
                    <Form.Select aria-label="Crypto Pair"
                                 name="cryptoPair"
                                 value={inputsData.cryptoPair}
                                 onChange={handleChange}>
                      <option value="BTCUSDT">BTCUSDT</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="p-1 font-weight-bold"
                      style={{fontWeight: "bold"}}>
                      Interval
                    </div>
                    <Form.Select aria-label="Interval"
                                 name="interval"
                                 value={inputsData.interval}
                                 onChange={handleChange}>
                      <option value="15m">15m</option>
                      <option value="30m">30m</option>
                      <option value="1h">1h</option>
                      <option value="2h">2h</option>
                      <option value="4h">4h</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="p-1 font-weight-bold"
                      style={{fontWeight: "bold"}}>
                      Month
                    </div>
                    <Form.Select aria-label="FromDate"
                                 name="month"
                                 value={inputsData.month}
                                 isInvalid={inputsError}
                                 onChange={handleChange}>
                      {
                        [...Array.from({length: 12}, (x, i) => i + 1)]
                          .map((el, idx) =>
                            <option key={idx} value={el}>
                              {`2021-${('0' + el).slice(-2)}`}
                            </option>
                          )
                      }
                    </Form.Select>
                  </div>
                </div>
              </Grid>
              <Grid container justifyContent={"center"}>
                <Grid item lg={2} sm={4} xs={12}>
                  <Button fullWidth variant={"contained"} onClick={fetchTestData} className="mt-4">
                    Perform test
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {
              chartData ? <SimulationChart
                historicalData={chartData.historicalData}
                results={chartData.results}
                startCash={inputsData.startCash}
                setInputsData={setInputsData}/> : ""
            }
          </WithLoading>
      }
    </div>
  );
};

export default Simulation;
