import React from "react";
import PictureButton from "../components/pictureButton";
import {useNavigate, useOutletContext} from "react-router-dom";

const buttonContent = [
  {
    url: './Bot4.jpg',
    title: "Let's get your first bot!",
    width: '100%',
  }
];

const Home = () => {
  const {isLogged} = useOutletContext()
  let navigate = useNavigate()

  const handleButtonClick = () => {
    if(isLogged) {
      navigate("/crypto-bots")
      return
    }

    navigate("sign-up")
  }

  return (
    <>
      <div className="row" style={{ marginTop: "5%" }}>
        <div className="col-8 offset-2">
          <div className="row">
            <h2 className="text-center">
              You don't to be an expert to earn money buying crypto!<br/>
              Our bots will do it for you!
            </h2>
          </div>
          <p className="" style={{textAlign: "justify"}}>
            Our bots are the future of the stock market game. Do not let your
            money just lie in your account, invest and see how your account
            balance grows day by day. You don't need to be familiar with crypto
            currencies or the stock market, our bots will help get rich. If you
            are not sure and you are afraid of losses. We have prepared a
            simulation that will dispel your doubts. Any delay in money wasted
            that could have been yours!
          </p>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col offset-2">
          <h2>Let's get started</h2>
        </div>
      </div>
      <div className="row mt-5">
        <div className={"col-8 offset-2 col-md-4 offset-md-4"}>
          <PictureButton data={buttonContent} clickFunction={handleButtonClick}/>
        </div>
      </div>
    </>
  );
};

export default Home;
