import api from "./api";

const getApiKeys = (market) => {
  return api.get("/apiKeys?market=" + market)
}

const addApiKeys = (publicKey, secretKey, market, exchangeName) => {
  return api
    .post("/apiKeys", {
      publicKey,
      secretKey,
      market,
      exchangeName,
    })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return error.response;
    });
}

const getBot = (id) => {
  return api.get("/bots?id=" + id);
};

const updateBot = (botTemplateId, apiKeysId) => {
  return api.patch('/bots', {
    botTemplateId,
    apiKeysId
  })
}

const getBots = () => {
  return api.get("/bots");
};

const addBot = (botTemplateId, apiKeysId) => {
  return api
    .post("/bots", {
      botTemplateId,
      apiKeysId
    });
};

const getTransactions = (from, to) => {
  return api.get(`/bots/transactions?from=${from}&to=${to}`)
}

const getTemplates = () => {
  return api.get('bots/botTemplates')
}

const toggleBot = (botId) => {
  return api.patch(`bots/${botId}/toggle`)
}

const getUpdateInfo = (botId) => {
  return api.get(`updateInfo/${botId}`)
}

const getAllTimeProfit = (from, to) => {
  return api.get(`bots/timeframePNL?from=${from}&to=${to}`)
}

const BotService = {
  // used
  addBot,
  getBot,
  updateBot,
  getBots,
  getApiKeys,
  addApiKeys,
  getTransactions,
  getTemplates,
  toggleBot,
  getUpdateInfo,
  getAllTimeProfit
};

export default BotService;
