import React, {useEffect, useCallback} from "react";
import PropTypes from 'prop-types'
import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts';

// const LINES_DISPLAYED = 100
const viewportHeight = window.innerHeight

const getMinValue = (acc, el) => acc < el ? acc : el
const getMaxValue = (acc, el) => acc > el ? acc : el

const parseToCash = (backtestData, cash) => {
  return backtestData.filter((el) => {
    return el.action === "TAKE_PROFIT" || el.action === "STOP_LOSS"
  }).map((el, i, arr) => {
    return (cash + el.profit + arr.slice(0, i).reduce((a, b) => a + b.profit, 0)).toFixed(2)
  })
}

const parseToDataPoints = (backtestData) => {
  return backtestData.filter((el) => {
    return el.action === "TAKE_PROFIT" || el.action === "STOP_LOSS"
  }).map((el) => {
    return timestampToDate(el.timestamp)
  })
}

const timestampToDate = (timestamp) => {
  const a = new Date(timestamp)
  const year = a.getFullYear()
  const month = ('0' + (a.getMonth() + 1)).slice(-2)
  const date = ('0' + a.getDate()).slice(-2)

  return date + '-' + month + '-' + year;
}

const SimulationChart = ({historicalData, results, startCash, setInputsData}) => {
  let options = {
    series: [{
      // data: historicalData.filter((el, idx) => idx < LINES_DISPLAYED)
      data: historicalData
    }],
    options: {
      chart: {
        type: 'candlestick',
        height: viewportHeight * 0.4,
        id: 'candles',
        toolbar: {
          autoSelected: 'pan',
          show: false
        },
      },
      plotOptions: {
        candlestick: {
          colors: {
            upward: '#3C90EB',
            downward: '#DF7D46'
          }
        }
      },
      title: {
        text: 'Crypto Candlesticks',
        align: 'left'
      },
      xaxis: {
        type: 'datetime',
        labels: {
          // format: 'HH:mm, dd-MM-yy',
          format: 'dd-MM-yy',
        }
      },
      yaxis: {
        show: true,
        title: {
          text: 'Price',
          style: {
            fontSize: '14px'
          }
        },
        decimalsInFloat: 0,
        forceNiceScale: true
      }
    }
  };

  // eslint-disable-next-line
  let optionsBar = {
    series: [{
      // data: historicalData.filter((item, idx) => idx < LINES_DISPLAYED)
      data: historicalData
    }],
    options: {
      chart: {
        height: 160,
        type: 'candlestick',
        brush: {
          enabled: true,
          target: 'candles',
        },
        events: {
          selection: (chartContext, {xaxis}) => {
            // Getting X and Y data form chart
            const closes = chartContext.series.ctx.data.twoDSeries
            const timestamps = chartContext.series.ctx.data.twoDSeriesX

            // Getting marked X data
            const markedTimestamps = timestamps.filter(el =>
              el > xaxis.min && el < xaxis.max
            )

            // Getting marked Y data
            const markedCloses = closes.filter((_, idx) =>
              timestamps[idx] > xaxis.min && timestamps[idx] < xaxis.max
            )

            // Calculate min i max of the X marked data
            const min_time = markedTimestamps.reduce(getMinValue)
            const max_time = markedTimestamps.reduce(getMaxValue)

            // Calculate min i max of the Y marked data
            const min_close = markedCloses.reduce(getMinValue)
            const max_close = markedCloses.reduce(getMaxValue)

            // Update main chart options
            ApexCharts.exec('candles', 'updateOptions', {
              xaxis: {
                min: min_time,
                max: max_time
              },
              yaxis: {
                show: true,
                title: {
                  text: 'Price',
                  style: {
                    fontSize: '14px'
                  }
                },
                decimalsInFloat: 4,
                forceNiceScale: true,
                min: min_close - (max_close - min_close) * 0.5,
                max: max_close + (max_close - min_close) * 0.5
              }
            }, /* redrawPaths */false, /* animate */true);

            // calculate profit
            calculateProfit(min_time, max_time)
          }
        },
        selection: {
          enabled: true,
          xaxis: {
            min: historicalData[0][0],
            // max: historicalData[LINES_DISPLAYED - 1][0]
            max: historicalData[historicalData.length - 1][0]
          },
        },
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        candlestick: {
          colors: {
            upward: '#3C90EB',
            downward: '#DF7D46'
          }
        }
      },
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'HH:mm, dd-MM-yy',
        }
      },
      yaxis: {
        show: true,
        title: {
          text: 'Price',
          style: {
            fontSize: '14px'
          }
        },
        decimalsInFloat: 4,
        forceNiceScale: true,
      }
    }
  };

  let optionsLine = {
    series: [{
      name: "Your cash",
      data: [startCash, ...parseToCash(results, startCash)]
    }],
    options: {
      chart: {
        type: 'line',
        height: 350
      },
      stroke: {
        curve: 'stepline',
      },
      dataLabels: {
        enabled: false
      },
      title: {
        text: 'Your Cash',
        align: 'left'
      },
      markers: {
        size: 4,
      },
      xaxis: {
        categories: [timestampToDate(results[0].timestamp), ...parseToDataPoints(results)],
        tickPlacement: "on"
      },
      yaxis: {
        show: true,
        title: {
          text: 'Cash',
          style: {
            fontSize: '14px'
          }
        },
        decimalsInFloat: 0,
        forceNiceScale: true
      }
    }
  };
  // function called after clicking "Simulate" button
  const calculateProfit = useCallback((markedChartStartDate, markedChartEndDate) => {
    const backtraderData = results

    let wallet = startCash
    let profit = 0

    const btMarkedData = backtraderData.filter((row) =>
      row.timestamp >= markedChartStartDate && row.timestamp <= markedChartEndDate);

    /*
    * Code for calculating profit on Futures market
    */
    btMarkedData.forEach(el => {
      if (el.action === "TAKE_PROFIT" || el.action === "STOP_LOSS") {
        profit += el.profit
      }
    })

    // Displaying result
    setInputsData(prevState => ({
      ...prevState,
      endCash: (parseFloat(wallet) + profit).toFixed(2)
    }))
  }, [results, setInputsData, startCash])

  useEffect(() => {
    const minTime = historicalData[0][0]
    const maxTime = historicalData[historicalData.length - 1][0]
    calculateProfit(minTime, maxTime)
  }, [historicalData, calculateProfit])

  return (
    <div style={{width: '95%'}}>
      <Chart
        options={options.options}
        series={options.series}
        type="candlestick"
        width="100%"
        height={400}
      />
      <Chart
        options={optionsLine.options}
        series={optionsLine.series}
        type="line"
        width="100%"
        height={400}
      />
    </div>
  )
}

SimulationChart.propTypes = {
  historicalData: PropTypes.array.isRequired,
  results: PropTypes.array.isRequired,
  startCash:PropTypes.number.isRequired,
  setInputsData: PropTypes.func.isRequired
}

export default SimulationChart;