import jwt_decode from "jwt-decode";

const getLocalRefreshToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.refreshToken;
};

const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.accessToken;
};

const updateLocalAccessToken = (token) => {
  let user = JSON.parse(localStorage.getItem("user"));
  user.accessToken = token;
  localStorage.setItem("user", JSON.stringify(user));
};

const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const setUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem("user");
};

const getLocalAuthCodeToken = () => {
  const authCode = JSON.parse(localStorage.getItem("authCode"));
  return authCode?.authToken;
};

const updateLocalAuthCodeToken = (token) => {
  let authCode = JSON.parse(localStorage.getItem("authCode"));
  authCode.authToken = token;
  localStorage.setItem("authCode", JSON.stringify(authCode));
};

const getAuthCode = () => {
  return JSON.parse(localStorage.getItem("authCode"));
};

const setAuthCode = (authCode) => {
  localStorage.setItem("authCode", JSON.stringify(authCode));
};

const removeAuthCode = () => {
  localStorage.removeItem("authCode");
};

const isTokenPresent = () => {
  const token = getLocalAccessToken();
  return token?.length > 0
}

const isTokenExpired = () => {
  const token = getLocalAccessToken()
  const decoder = jwt_decode(token);
  const expiry = new Date(1000 * decoder.exp);
  const now = new Date(Date.now());
  return expiry <= now
}

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
  isTokenPresent,
  isTokenExpired,
  getLocalAuthCodeToken,
  updateLocalAuthCodeToken,
  getAuthCode,
  setAuthCode,
  removeAuthCode
};

export default TokenService; // all used
