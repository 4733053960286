function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function formatDate(date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds())
    ].join(':')
  );
}

export function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);
  const intervals = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "week", seconds: 604800 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  for (let i = 0; i < intervals.length; i++) {
    const interval = intervals[i];
    const count = Math.floor(seconds / interval.seconds);
    if (count >= 1) {
      return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
    }
  }

  return "just now";
}

export const TIMEFRAME = {
  Today: 'today',
  ThisWeek: 'ThisWeek',
  ThisMonth: 'ThisMonth',
  Last3Months: 'Last3Months',
  Last6Months: 'Last6Months',
  ThisYear: 'ThisYear',
  Max: 'Max',
  Last90Days: 'Last90Days'
}

function toIsoString(date) {
  let tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function(num) {
      return (num < 10 ? '0' : '') + num;
    };

  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    dif + pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' + pad(Math.abs(tzo) % 60);
}

export function getPeriodDates(period) {
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();
  let day = today.getDate();
  let dayOfWeek = today.getDay();

  let start, end;
  switch (period) {
    case TIMEFRAME.Today:
      start = new Date(year, month, day);
      end = new Date(year, month, day, 23, 59, 59);
      break;
    case TIMEFRAME.ThisWeek:
      start = new Date(year, month, day - (dayOfWeek + 6) % 7);
      end = new Date(year, month, day, 23, 59, 59);
      break;
    case TIMEFRAME.ThisMonth:
      start = new Date(year, month, 1);
      end = new Date(year, month + 1, 0, 23, 59, 59);
      break;
    case TIMEFRAME.Last3Months:
      start = new Date(year, month - 2, 1);
      end = new Date(year, month + 1, 0, 23, 59, 59);
      break;
    case TIMEFRAME.Last6Months:
      start = new Date(year, month - 5, 1);
      end = new Date(year, month + 1, 0, 23, 59, 59);
      break;
    case TIMEFRAME.ThisYear:
      start = new Date(year, month - 11, 1);
      end = new Date(year, month + 1, 0, 23, 59, 59);
      break;
    case TIMEFRAME.Max:
      start = new Date(year - 100, month, day);
      end = new Date(year, month + 1, 0, 23, 59, 59);
      break;
    case TIMEFRAME.Last90Days:
      start = new Date(year, month, day - 89);
      end = new Date(year, month, day, 23, 59, 59);
      break;
    default:
      return "Invalid period";
  }

  let from = toIsoString(start).slice(0, 19).replace('T', ' ')
  let to = toIsoString(end).slice(0, 19).replace('T', ' ')

  return {from, to};
}
