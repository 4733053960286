import React from "react";
import PropTypes from "prop-types";
import {blue} from "@mui/material/colors";
import CardHeader from "@mui/material/CardHeader";
import {Divider, Typography} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import DoneIcon from "@mui/icons-material/Done";
import Card from "@mui/material/Card";
import {styled} from "@mui/material/styles";

const StyledTypography= styled(Typography)(({theme}) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 18,
  }
}))

const SelectedPlanCard = ({title, body}) => {
  return (
    <Card sx={{borderRadius: 5, borderWidth: 3, borderColor: blue[500], p: 2, ml: "auto", mr: "auto"}}
          variant="outlined">
      <CardHeader
        title={title}
        titleTypographyProps={{variant: "h4", style: {textAlign: "center", marginBottom: 10}}}
      />
      <Divider/>
      <CardContent >
        {body.map((item, idx) =>
          <StyledTypography color="text.secondary" key={idx} sx={{mb: 2}}>
            <DoneIcon sx={{mr: 1}}/>
            {item}
          </StyledTypography>
        )}
      </CardContent>
    </Card>
  )
}

SelectedPlanCard.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.arrayOf(PropTypes.string)
}

export default SelectedPlanCard;