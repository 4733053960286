import React from "react";
import PropTypes from "prop-types"
import {Grid} from 'gridjs-react';
import "gridjs/dist/theme/mermaid.css";

const dateFormatter = (cell) => {
  return cell.replace(" ", ", ")
}

const cellFormatter = (cell) => cell

function createData({date, botId, logLevel, message}) {
  return [date, botId, logLevel, message];
}

function createColumn(id, name, width, formatter, sort) {
  return {id, name, width, formatter, sort}
}

const NUMBER_OF_COLUMNS = 3
const column_width = '' + 1.0 / NUMBER_OF_COLUMNS + '%'
const message_column_width = '' + 2.0 / NUMBER_OF_COLUMNS + '%'
const columns = [
  createColumn('date','Date', column_width, dateFormatter),
  createColumn('botId','Bot ID', column_width, cellFormatter),
  createColumn('logLevel','Log Level', column_width, cellFormatter),
  createColumn('msg','Message', message_column_width, cellFormatter, false),
]

const LogsTable = ({rows}) => {
  return (
    <Grid
      search={true}
      data={rows.map(row => createData(row))}
      columns={columns.map(col => {
        if (['Date', 'Bot ID', 'Log Level'].includes(col.name)) {
          return {
            ...col,
            attributes: cell => {
              if (cell !== null) {
                return {
                  'data-cell-content': cell,
                  'style': 'text-align: center',
                };
              }
            }
          }
        }
        return col
      })}
      sort={true}

      pagination={{
        enabled: true,
        limit: 20,
      }}

      className={{
        th: 'table-th',
        td: 'table-td',
        footer: 'table-footer'
      }}
    />
  )
}

LogsTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string.isRequired,
    botId: PropTypes.number.isRequired,
    logLevel: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired
  })).isRequired
}


export default LogsTable;
