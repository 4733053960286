import React from "react";
import PropTypes from "prop-types"
import {Grid} from 'gridjs-react';
import "gridjs/dist/theme/mermaid.css";
import {_} from "gridjs-react"; // function for inserting react elements into table
import {IconButton, Tooltip} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

const dateFormatter = (cell) => {
  return cell.replace(" ", ", ")
}

const profitFormatter = (cell, row) => {
  if (row.cells[row.length - 2].data < 0) {
    return _(<b style={{color: "red"}}>{cell}</b>)
  } else if (row.cells[row.length - 2].data > 0) {
    return _(<b style={{color: "green"}}>{cell}</b>)
  }
  return cell
}

const cellFormatter = (cell) => cell

const columnLowerIndexFormatter = (name, index) => _(<div>{name} <sub style={{color: "#0d6efd"}}>[{index}]</sub></div>)

const columnTooltipFormatter = (name, tooltipContent) => _(
  <div>
    <Tooltip title={tooltipContent} placement={'top'}>
      <div>
        {name}
        <IconButton color={"primary"}>
          <InfoIcon/>
        </IconButton>
      </div>
    </Tooltip>
  </div>
)

function createData({date, action, side, amount, price, wallet, profit, profitPerc}) {
  return [date, action, side, amount, price, wallet, profit, profitPerc];
}

function createColumn(id, name, width, formatter, sort) {
  return {id, name, width, formatter, sort}
}

const NUMBER_OF_COLUMNS = 8
const column_width = '' + 1.0 / NUMBER_OF_COLUMNS + '%'
const date_column_width = '' + 2.0 / NUMBER_OF_COLUMNS + '%'
const columns = [
  createColumn('date', 'Date', date_column_width, dateFormatter),
  createColumn('action', 'Action', column_width, cellFormatter, false),
  createColumn('side', columnTooltipFormatter('Side',
    "Long - investment in a stock with the expectation of price appreciation." +
    " Short - investment in a stock with the expectation of price decline."), column_width, cellFormatter, false),
  createColumn('amount', columnLowerIndexFormatter("Amount", "BTC"), column_width, cellFormatter, false),
  createColumn('price', columnLowerIndexFormatter("Price", "USDT"), column_width, cellFormatter, false),
  createColumn('wallet', columnLowerIndexFormatter("Wallet", "USDT"), column_width, cellFormatter, false),
  createColumn('profit', columnLowerIndexFormatter("Profit", "USDT"), column_width, profitFormatter),
  createColumn('profitPerc', columnLowerIndexFormatter("Profit", "%"), column_width, profitFormatter),
]

const TransactionsTable = ({rows}) => {
  return (
    <Grid
      data={rows.map(row => createData(row))}
      columns={columns.map((col, idx) => {
        if (idx <= 2) {
          return {
            ...col,
            attributes: cell => {
              if (cell) {
                return {
                  'data-cell-content': cell,
                  'style': 'text-align: center',
                };
              }
            }
          }
        }
        return col
      })}
      sort={true}

      pagination={{
        enabled: true,
        limit: 20,
      }}

      className={{
        th: 'table-th',
        td: 'table-td',
        footer: 'table-footer'
      }}
    />
  )
}

TransactionsTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    side: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    wallet: PropTypes.string.isRequired,
    profit: PropTypes.string.isRequired,
    profitPerc: PropTypes.string.isRequired,
  })).isRequired
}


export default TransactionsTable;
