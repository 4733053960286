import React, {useEffect, useState} from "react";
import WithLoading from "../components/loadingWrapper";
import {Grid, TextField, Typography} from "@mui/material";
import LogsTable from "../components/logsTable";
import AdminService from "../services/admin.service";
import {formatDate, getPeriodDates, TIMEFRAME} from "../utils/dateFunctions";
import {Form} from "react-bootstrap";
import {capitalizeFirstLetter} from "../utils/stringFunctions";
import {useOutletContext} from "react-router-dom";
import LogLevel from "../utils/logLevel";
import {BotIdInput} from "../components/customInputs";
import HeartbeatsTable from "../components/heartbeatsTable";
import axios from "axios";

const AdminPanel = () => {
  const [errors, setErrors] = useState([])
  const [displayedErrors, setDisplayedErrors] = useState([])
  const [heartbeats, setHeartbeats] = useState([])
  const [selectedPeriod, setSelectedPeriod] = useState(TIMEFRAME.Today)
  const [botId, setBotId] = useState("")
  const [selectedLogLevel, setSelectedLogLevel] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const {isJwtTokenValid} = useOutletContext()


  useEffect(() => {
    if (!isJwtTokenValid()) {
      return
    }

    const tf = getPeriodDates(selectedPeriod)
    const getLogsRequest = AdminService.getLogs(tf.from, tf.to)
    const getHeartbeatsRequest = AdminService.getHeartbeats()

    axios.all([getLogsRequest, getHeartbeatsRequest]).then(axios.spread((...responses) => {
      const getErrorsResponse = responses[0]
      const getHeartbeatsResponse = responses[1]

      const er = getErrorsResponse.data.map(row => ({
        date: formatDate(new Date(row.timestamp)),
        botId: parseInt(row.botId),
        logLevel: parseInt(row.logLevel),
        message: row.message
      })).sort((a, b) => (b.date > a.date) ? 1 : ((a.date > b.date) ? -1 : 0))
      setErrors(er)
      setDisplayedErrors(er)

      const hb = getHeartbeatsResponse.data.map(row => ({
        botId: parseInt(row.botId),
        status: row.status,
        user: row.userEmail,
        lastCheckTime: formatDate(new Date(row.lastValidHeartbeatTime)),
        lastHeartbeatTime: formatDate(new Date(row.lastHeartbeatTime))
      }))
      setHeartbeats(hb)

      setIsLoading(false)
    }))

  }, [selectedPeriod, isJwtTokenValid])

  const filterErrors = (tempBotId, tempLogLevel) => {
    let tempErrors
    if (!isNaN(tempBotId) && !isNaN(tempLogLevel)) {
      tempErrors = errors.filter(e => e.botId === tempBotId && e.logLevel === tempLogLevel)
      setDisplayedErrors(tempErrors)
    } else if (!isNaN(tempLogLevel)) {
      tempErrors = errors.filter(e => e.logLevel === tempLogLevel)
      setDisplayedErrors(tempErrors)
    } else if (!isNaN(tempBotId)) {
      tempErrors = errors.filter(e => e.botId === tempBotId)
      setDisplayedErrors(tempErrors)
    } else {
      setDisplayedErrors(errors)
    }
  }

  return (
    <div className="row" style={{marginTop: "7%"}}>
      <WithLoading isLoading={isLoading}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}} className={"mt-5"}>
          <Typography variant={"h5"} gutterBottom>
            Heartbeat
          </Typography>
        </div>
        <Grid container justifyContent={"center"} spacing={2} sx={{mt: 2}}>
          <Grid item lg={11} xs={12}>
            <HeartbeatsTable rows={
              heartbeats
            }/>
          </Grid>
        </Grid>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}} className={"mt-5"}>
          <Typography variant={"h5"} gutterBottom>
            Logs
          </Typography>
        </div>
        <Grid container justifyContent={"center"} spacing={2} sx={{mt: 2}}>
          <Grid item lg={2} xs={12}>
            <Form.Select aria-label="Periods"
                         name="periods"
                         value={selectedPeriod}
                         onChange={event => {
                           setSelectedPeriod(event.target.value)
                         }}>
              {
                Object.keys(TIMEFRAME).map((e, idx) =>
                  <option key={idx} value={TIMEFRAME[e]}>{capitalizeFirstLetter(e.split(/(?=[A-Z]|\d+)/g).join(" "))}</option>
                )
              }
            </Form.Select>
          </Grid>
          <Grid item lg={2} xs={12}>
            <TextField
              InputProps={{
                inputComponent: BotIdInput,
              }}
              fullWidth
              size={"small"}
              label="BotId"
              name="botId"
              value={botId}
              onChange={(event) => {
                setBotId(event.target.value)
                filterErrors(parseInt(event.target.value), parseInt(selectedLogLevel))
              }}
            />
          </Grid>
          <Grid item lg={2} xs={12}>
            <Form.Select aria-label="LogLevels"
                         name="logLevels"
                         value={selectedLogLevel}
                         onChange={event => {
                           setSelectedLogLevel(event.target.value)
                           filterErrors(parseInt(botId), parseInt(event.target.value))
                         }}>
              {
                Object.keys({All: "", ...LogLevel}).map((e, idx) =>
                  <option key={idx} value={LogLevel[e]}>{capitalizeFirstLetter(e)}</option>
                )
              }
            </Form.Select>
          </Grid>
          <Grid item lg={5} xs={0}/>
        </Grid>
        <Grid container justifyContent={"center"} spacing={2} sx={{mt: 2}}>
          <Grid item lg={11} xs={12}>
            <LogsTable rows={
              displayedErrors
            }/>
          </Grid>
        </Grid>
      </WithLoading>
    </div>
  )
}

export default AdminPanel;