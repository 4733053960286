import React from "react";
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import {Box, Button, Divider} from "@mui/material";
import {useNavigate, useOutletContext} from 'react-router-dom';
import styled from "@emotion/styled";
import {blue, green} from "@mui/material/colors";
import DoneIcon from '@mui/icons-material/Done';


const StyledButton = styled(Button)(({theme}) => ({
  fontWeight: 600,
  borderWidth: 3,
  borderColor: green[300],
  '&:hover': {
    fontWeight: 600,
    borderWidth: 3,
    borderColor: green[500],
  },
}));

const PlanCard = ({content}) => {
  const navigate = useNavigate()
  const {isLogged} = useOutletContext()

  return (
    <Box sx={{display: "flex", justifyContent: "center"}}>
      <Card sx={{maxWidth: 375, borderRadius: 5, borderWidth: 3, borderColor: blue[500], p: 2}} variant="outlined">
        <CardHeader
          title={content.title}
          titleTypographyProps={{variant: "h5", style: {textAlign: "center", marginBottom: 10}}}
          subheader={`${content.price}$/mo.`}
          subheaderTypographyProps={{variant: "h3", style: {textAlign: "center"}}}
        />
        <Divider/>
        <CardContent sx={{minHeight: 425}}>
          <Typography color="text.secondary" sx={{mb: 4, fontSize: 18, minHeight: 75}}>
            {content.description}
          </Typography>
          {content.body.map((item, idx) =>
            <Typography color="text.secondary" key={idx} sx={{mb: 2, fontSize: 18}}>
              <DoneIcon sx={{mr: 1}}/>
              {item}
            </Typography>
          )}
        </CardContent>
        <CardActions disableSpacing>
          <Button
            sx={{fontSize: 16}}
            variant={"outlined"}
            onClick={() => {
              if(!isLogged) {
                navigate("/sign-in")
                return
              }
              navigate("/payment", {state: content})}
            }>
            Free 30-day trial
          </Button>
          <StyledButton
            sx={{marginLeft: "auto", fontSize: 18}}
            variant={"contained"}
            color="success"
            onClick={() => {
              if(!isLogged) {
                navigate("/sign-in")
                return
              }
              navigate("/payment", {state: content})}
            }>
            BUY
          </StyledButton>
        </CardActions>
      </Card>
    </Box>
  );
}

PlanCard.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    body: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired
}

export default PlanCard