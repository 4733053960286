import React from "react";
import {Box, Typography} from "@mui/material";
import FaqCard from "../components/faqCard";
import {styled} from '@mui/material/styles';

const faqCardsContent = [
  {
    question: "How do I pay for a bot?",
    answer: "In order to use bot you have to pay monthly subscription. You can pay using credit card."
  },
  {
    question: "Do I need to have an account to buy the bot ?",
    answer: "Yes, you need to have an account."
  },
  {
    question: "Can I purchase more that one bot with same account?",
    answer: "No, you can buy only one bot per account."
  },
  {
    question: "How those bots work ?",
    answer: "They predict the ups and downs in the value of cryptocurrencies in order to earn money"
  },
  {
    question: "Is my money safe ?",
    answer: "Of course. We cannot withdraw money from your account using api keys."
  },
]

const FaqBox = styled('div')(({theme}) => ({
  [theme.breakpoints.down('sm')]: {
    width: "80%",
  },
  [theme.breakpoints.up('sm')]: {
    width: "60%",
  }
}));

const Faq = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: "90vh",
      }}
    >
      <FaqBox sx={{pt: 1}}>
        <Box sx={{textAlign: "center"}}>
          <Typography variant={"h4"}>
            FAQ
          </Typography>
        </Box>
        <Box sx={{mt: 4}}>
          {faqCardsContent.map((item, idx) =>
            <FaqCard data={item} key={idx}/>
          )}
        </Box>
      </FaqBox>
    </div>
  );
};

export default Faq;
