import React from "react";
import PropTypes from "prop-types"
import Spinner from 'react-bootstrap/Spinner';

// Higher order component
const WithLoading = ({children, isLoading, height="30vh", size="5rem"}) => {
  return (
    <>
      {
        !isLoading ? children :
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: height,
          }}>
            <Spinner animation="border" variant="primary" style={{width: size, height:size}}/>
          </div>
      }
    </>
  );
}

WithLoading.propTypes = {
  children:PropTypes.node,
  isLoading: PropTypes.bool.isRequired
}

export default WithLoading;