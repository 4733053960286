import api from "./api";

const getLogs = (from, to) => {
  if(from === undefined || to === undefined) {
    return api.get(`/admin/logs`)
  }
  return api.get(`/admin/logs?from=${from}&to=${to}`)
}

const getHeartbeats = () => {
  return api.get(`/admin/heartBeats`)
}

const AdminService = {
  getLogs,
  getHeartbeats
};

export default AdminService;