import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const GoogleCaptcha = ({recaptchaRef}) => {
  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey={process.env.REACT_APP_SITE_KEY}
      size="invisible"
    />
  )
}

export default GoogleCaptcha;