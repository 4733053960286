import api from "./api";
import TokenService from "./token.service";

const register = (name, email, password) => {
  return api
    .post("/accounts/register", {
      name,
      email,
      password,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

const login = (email, password) => {
  return api
    .post("/accounts/login", {
      email,
      password,
    })
    .then((response) => {
      if (response?.data?.accessToken) {
        TokenService.setUser(response.data);
      } else if (response?.data?.authToken) {
        TokenService.setAuthCode(response.data);
      }
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

const authAccount = (authCode) => {
  const userId = TokenService.getAuthCode().id
  return api.post(`accounts/${userId}/authorize`, {
    authCode
  }).then((response) => {
    TokenService.removeAuthCode()
    if (response?.data?.accessToken) {
      TokenService.setUser(response.data);
    }
  })
}

const regenerateAuthCode = () => {
  const userId = TokenService.getAuthCode().id
  return api.get(`accounts/${userId}/generateAuthCode`).then((response) => {
    TokenService.setAuthCode(response.data)
    return response.data;
  }).catch((error) => {
    return error.response.data;
  });
}

const logout = () => {
  TokenService.removeUser();
};

const verifyCaptcha = (token) => {
  return api.post(`/accounts/verifyCaptcha`, token)
}


const AuthService = {
  register,
  login,
  logout,
  authAccount,
  regenerateAuthCode,
  verifyCaptcha
};

export default AuthService; // all used
