import React from "react";
import PropTypes from "prop-types"
import {_, Grid} from 'gridjs-react';
import "gridjs/dist/theme/mermaid.css";
import HeartbeatStatus from "../utils/heartbeatStatus";
import {capitalizeFirstLetter} from "../utils/stringFunctions";
import {timeSince} from "../utils/dateFunctions"

const statusFormatter = (cell, row) => {
  if (cell === HeartbeatStatus.Working) {
    return _(<b style={{color: "green"}}>{capitalizeFirstLetter(cell)}</b>)
  } else if (cell === HeartbeatStatus.Error) {
    return _(<b style={{color: "red"}}>{capitalizeFirstLetter(cell)}</b>)
  } else if (cell === HeartbeatStatus.Warning) {
    return _(<b style={{color: "darkorange"}}>{capitalizeFirstLetter(cell)}</b>)
  }
  return capitalizeFirstLetter(cell)
}

const dateFormatter = (cell) => {
  return timeSince(new Date(cell))
}

const cellFormatter = (cell) => cell

function createData({botId, status, user, lastCheckTime,  lastHeartbeatTime}) {
  return [botId, status, user, lastCheckTime, lastHeartbeatTime];
}

function createColumn(id, name, width, formatter, sort) {
  return {id, name, width, formatter, sort}
}

const NUMBER_OF_COLUMNS = 5
const column_width = '' + 1.0 / NUMBER_OF_COLUMNS + '%'
const columns = [
  createColumn('botId','Bot ID', column_width, cellFormatter, false),
  createColumn('status','Status', column_width, statusFormatter, false),
  createColumn('user','User', column_width, cellFormatter, false),
  createColumn('lastCheckTime','Last Check Time', column_width, dateFormatter, false),
  createColumn('lastHeartbeatTime','Last Heartbeat Time', column_width, dateFormatter, false)
]

const HeartbeatsTable = ({rows}) => {
  return (
    <Grid
      data={rows.map(row => createData(row))}
      columns={columns.map(col => {
        return {
          ...col,
          attributes: cell => {
            if (cell !== null) {
              return {
                'data-cell-content': cell,
                'style': 'text-align: center',
              };
            }
          }
        }
      })}
      sort={true}

      pagination={{
        enabled: true,
        limit: 20,
      }}

      className={{
        th: 'table-th',
        td: 'table-td',
        footer: 'table-footer'
      }}
    />
  )
}

HeartbeatsTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    lastHeartbeatTime: PropTypes.string.isRequired,
    botId: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
  })).isRequired
}


export default HeartbeatsTable;
