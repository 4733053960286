import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import TokenService from "../../services/token.service";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {NavDropdown} from "react-bootstrap";
import "../../App.css";
import AuthService from "../../services/auth.service";
import {Box, Button} from "@mui/material";
import UserRole from "../../utils/userRole";
import AvatarMenu from "../avatarMenu";

function handleScroll() {
  window.scroll({
    top: document.body.offsetHeight,
    left: 0,
    behavior: "smooth",
  });
}

const DROPDOWN_ITEMS = ["/crypto-bots", "/transactions", "/admin-panel"]

const NavbarEx = ({isLogged, setIsLogged, userName, userEmail, userRole}) => {
  let navigate = useNavigate();
  let location = useLocation();
  const [isDropdownActive, setIsDropdownActive] = useState(false)
  const [isDropdownShow, setIsDropdownShow] = useState(false)
  const [navStyle, setNavStyle] = useState({paddingRight: 0})

  useEffect(() => {
    if (DROPDOWN_ITEMS.includes(location.pathname)) {
      setIsDropdownActive(true)
    } else {
      setIsDropdownActive(false)
    }
  }, [location])

  useEffect(() => {
    let user = TokenService.getUser();
    if (user?.accessToken.length > 0) {
      setIsLogged(true);
    }
  }, [setIsLogged]);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg={process.env.REACT_APP_ENV === "stag" ? "success": "light"}
      variant="light"
      fixed="top"
      style={navStyle}
    >
      <Container>
        <Nav.Link as={Link} to="/" href="/">
          <img src={require("../../images/logo.svg")} alt="logo"/>
        </Nav.Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav style={{width: "100%", alignItems: "center"}} activeKey={location.pathname}>
            {
              isLogged ?
                <>
                  <NavDropdown title="User panel" id="/crypto-bots"
                               onMouseEnter={() => {setIsDropdownShow(true)}}
                               onMouseLeave={() => {setIsDropdownShow(false)}}
                               show={isDropdownShow}
                               active={isDropdownActive}>
                    {
                      userRole === UserRole.Administrator ?
                        <>
                          <NavDropdown.Item as={Link} to="/admin-panel" href="/admin-panel">Admin Panel</NavDropdown.Item>
                          <NavDropdown.Divider/>
                        </>
                      : ""
                    }
                    <NavDropdown.Item as={Link} to="/crypto-bots" href="/crypto-bots">Bots</NavDropdown.Item>
                    <NavDropdown.Divider/>
                    <NavDropdown.Item as={Link} to="/transactions" href="/transactions">Transactions</NavDropdown.Item>
                  </NavDropdown>
                </>
                : ""
            }
            <Nav.Link as={Link} to="/products" href="/products">Pricing</Nav.Link>
            <Nav.Link as={Link} to="/simulation" href="/simulation">Simulation</Nav.Link>
            <Nav.Link as={Link} to="/faq" href="/faq">FAQ</Nav.Link>
            <Nav.Link onClick={handleScroll} className="me-lg-auto me-md-0 me-sx-0">Contact</Nav.Link>
            {
              !isLogged ?
                <>
                  <Nav.Link as={Link} to="/sign-up" href="/sign-up">Register</Nav.Link>
                  <Nav.Link as={Link} to="/sign-in" href="/sign-in" style={{padding: 0}}>
                    <Button variant={"contained"} fullWidth onClick={() => {
                      navigate("/sign-in")
                    }}>
                      Login
                    </Button>
                  </Nav.Link>
                </>
                :
                <>
                  <Box sx={{display: "flex", justifyContent: "center", mr: 3, ml: 3}}>
                    <AvatarMenu userName={userName} userEmail={userEmail} setNavStyle={setNavStyle}/>
                  </Box>
                  <Nav.Link as={Link} to="/sign-in" href="/sign-in" style={{padding: 0}}>
                    <Button
                      variant={"contained"}
                      fullWidth
                      onClick={() => {
                        setIsLogged(false);
                        AuthService.logout();
                        navigate("/");
                      }}>
                      Logout
                    </Button>
                  </Nav.Link>
                </>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

NavbarEx.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  setIsLogged: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  userRole: PropTypes.number.isRequired
}

export default NavbarEx;
