import React from "react";
import "./App.css";
import {Navigate, Route, Routes,} from "react-router-dom";
import Home from "./pages";
import Products from "./pages/products";
import SignUp from "./pages/signup";
import SignIn from "./pages/signin";
import Faq from "./pages/faq";
import "react-toastify/dist/ReactToastify.css";
import CryptoBots from "./pages/cryptobots";
import "bootstrap/dist/css/bootstrap.min.css";
import NotFound from "./pages/notfound";
import MainLayout from "./layouts/mainLayout";
import BotTransactions from "./pages/botTransactions";
import Simulation from "./pages/simulation";
import Payment from "./pages/payment";
import AuthCode from "./pages/authCode";
import AdminPanel from "./pages/adminPanel";
import PrivateRoute from "./components/Functional/privateRoute";

function App() {
  return (
    <Routes>
      <Route element={<MainLayout/>}>
        <Route index element={<Home/>}/>
        <Route path='/admin-panel' element={<PrivateRoute/>}>
          <Route path="/admin-panel" element={<AdminPanel />}/>
        </Route>
        <Route path='/transactions' element={<PrivateRoute/>}>
          <Route path="/transactions" element={<BotTransactions />}/>
        </Route>
        <Route path='/crypto-bots' element={<PrivateRoute/>}>
          <Route path="/crypto-bots" element={<CryptoBots />}/>
        </Route>
        <Route path="/products" element={<Products/>}/>
        <Route path="/simulation" element={<Simulation/>}/>
        <Route path="/faq" element={<Faq/>}/>
        <Route path="/payment" element={<Payment/>}/>
        <Route path="/sign-up" element={<SignUp/>}/>
        <Route path="/sign-in" element={<SignIn/>}/>
        <Route path="/not-found" element={<NotFound/>}/>
        <Route path="*" element={<Navigate replace to="/not-found"/>}/>
      </Route>
      <Route path="/authentication" element={<AuthCode/>}/>
    </Routes>
  );
}

export default App;