import axios from "axios";

const instance = axios.create({
  //baseURL: "http://127.0.0.1:5000",
  // baseURL: "https://wisemex.com/apiSim",
  baseURL: process.env.REACT_APP_API_URL_SIMULATION,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;