import React, {useEffect, useState} from "react";
import Nav from "react-bootstrap/Nav";
import {Link, useNavigate} from "react-router-dom";
import {Navbar} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {TextField} from "@mui/material";
import {AuthCodeInput} from "../components/customInputs";
import LoadingButton from "@mui/lab/LoadingButton";
import AuthService from "../services/auth.service";
import {toast, ToastContainer} from "react-toastify";
import TokenService from "../services/token.service";

const AuthCode = () => {
  const [authCode, setAuthCode] = useState("")
  const [isAwaitingSubmit, setIsAwaitingSubmit] = useState(false)
  const [isAwaitingRequestRespond, setIsAwaitingRequestRespond] = useState(false)
  let navigate = useNavigate();

  useEffect(() => {
    if (TokenService.getLocalAuthCodeToken() == null) {
      navigate("/not-found");
    }
  })

  const handleChange = (event) => {
    setAuthCode(event.target.value)
  };
  const handleSubmit = () => {
    setIsAwaitingSubmit(true)
    AuthService.authAccount(authCode)
      .then(() => {
        setAuthCode("")
        setIsAwaitingSubmit(false)
        navigate("/crypto-bots");
      })
      .catch(() => {
        setIsAwaitingSubmit(false)
        toast.error("The Code is wrong or expired");
      })
  }
  const handleRegenerateCode = () => {
    setIsAwaitingRequestRespond(true)
    AuthService.regenerateAuthCode()
      .then(() => {
        setIsAwaitingRequestRespond(false)
        toast.success("Code was sent to your email");
      })
  }

  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg={process.env.REACT_APP_ENV === "stag" ? "success" : "light"}
        variant="light"
        fixed="top"
      >
        <Container>
          <Nav.Link as={Link} to="#" href="#">
            <div style={{
              display: "flex",
              alignItems: "center"
            }}>
              <img src={require("../images/logo.svg")} alt="logo"/>
              <h3 style={{marginBottom: 0, paddingLeft: 10}}>Wisemex</h3>
            </div>
          </Nav.Link>
        </Container>
      </Navbar>
      <div style={{minHeight: "80vh", marginTop: 79, marginBottom: 100}}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "90vh",
            paddingTop: "2vh"
          }}
        >
          <div className="form" style={{padding: "30px 20px"}}>
            <div className="row">
              <h3 className="d-flex justify-content-center text-uppercase">
                Enter authorization code
              </h3>
            </div>
            <div className="mt-3">
              <TextField
                InputProps={{
                  inputComponent: AuthCodeInput,
                }}
                fullWidth
                size={"small"}
                label="Code"
                name="code"
                value={authCode}
                onChange={handleChange}/>
            </div>
            <div className="mt-3">
              <LoadingButton
                variant="contained"
                loading={isAwaitingSubmit}
                fullWidth={true}
                onClick={() => handleSubmit()}>
                Submit
              </LoadingButton>
            </div>
            <div className="mt-3">
              <LoadingButton
                variant="contained"
                loading={isAwaitingRequestRespond}
                fullWidth={true}
                size={"large"}
                onClick={() => handleRegenerateCode()}>
                Regenerate code
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" hideProgressBar/>
    </div>
  )
}

export default AuthCode;