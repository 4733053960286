import React from "react";
import {IMaskInput} from "react-imask";
import PropTypes from "prop-types";
import IMask from 'imask';

export const CardNumberInput = React.forwardRef(function TextMaskCustom(props, ref) {
  const {onChange, ...other} = props;
  return (
    <IMaskInput
      {...other}
      mask="0000 - 0000 - 0000 - 0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({target: {name: props.name, value: value.split(" - ").join("")}})}
      overwrite
    />
  );
});

export const CardNameInput = React.forwardRef(function TextMaskCustom(props, ref) {
  const {onChange, ...other} = props;
  return (
    <IMaskInput
      {...other}
      mask={/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u}
      autofix={true}
      inputRef={ref}
      onAccept={(value) => onChange({target: {name: props.name, value}})}
      overwrite
    />
  );
});

export const CardExpiryInput = React.forwardRef(function TextMaskCustom(props, ref) {
  const {onChange, ...other} = props;
  return (
    <IMaskInput
      {...other}
      mask={"m / Y"}
      autofix={true}
      blocks= {{
        m: {mask: IMask.MaskedRange, autofix: true, from: 1, to: 12, maxLength: 2},
        Y: {mask: IMask.MaskedRange, autofix: true, from: 23, to: 50, maxLength: 2}
      }}
      inputRef={ref}
      onAccept={(value) => onChange({target: {name: props.name, value: value.split(" / ").join("")}})}
      overwrite
    />
  );
});

export const CardCVCInput = React.forwardRef(function TextMaskCustom(props, ref) {
  const {onChange, ...other} = props;
  return (
    <IMaskInput
      {...other}
      mask={"000"}
      autofix={true}
      inputRef={ref}
      onAccept={(value) => onChange({target: {name: props.name, value}})}
      overwrite
    />
  );
});

export const AuthCodeInput = React.forwardRef(function TextMaskCustom(props, ref) {
  const {onChange, ...other} = props;
  return (
    <IMaskInput
      {...other}
      mask="000000"
      definitions={{
        '#': /[1-9]/,
      }}
      onAccept={(value) => onChange({target: {name: props.name, value}})}
      inputRef={ref}
      overwrite
    />
  );
});

export const BotIdInput = React.forwardRef(function TextMaskCustom(props, ref) {
  const {onChange, ...other} = props;
  return (
    <IMaskInput
      {...other}
      mask="000000"
      definitions={{
        '#': /[1-9]/,
      }}
      onAccept={(value) => onChange({target: {name: props.name, value}})}
      inputRef={ref}
      overwrite
    />
  );
});

const propsTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

CardNumberInput.propTypes = propsTypes
CardNameInput.propTypes = propsTypes
CardExpiryInput.propTypes = propsTypes
CardCVCInput.propTypes = propsTypes
AuthCodeInput.propTypes = propsTypes
BotIdInput.propTypes = propsTypes